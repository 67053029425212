import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/table";
import { license_columns } from "../../../../components/tables/tableheader";
import { licenseSchema } from "../../../../schemas";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../../components/Pagination";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal";
import { ArrowDownCircleIcon, ArrowDownIcon, ArrowUpCircleIcon, ArrowUpIcon, PlusCircleIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../../ApiServices/MasterServices";
import WhopServices from "../../../../ApiServices/WhopServices";
import { toast } from "react-toast";
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import moment from 'moment';
import { utils, writeFile, read } from 'xlsx';
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../../helper/commonHelper";
import Select from "react-select";

function LicenseList() {
  const pages = [{ title: "License List", href: "/license", module_id: 2 }];
  const animatedComponents = makeAnimated();
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [wp_lic_id, setLicenseid] = useState("");
  const [licenseList, setLicenseList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProductId, setSelectedProductId] = useState({ value: '', label: '' });
  const [selectedUserId, setSelectedUserId] = useState({ value: '', label: '' });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    wp_lic_id: null,
  });

  const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
    status: false,
    wp_lic_id: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    document.title = "Maqueensoft •  Licenses";
    getAllLicenses(3);
    getAllCustomer(1);
    getAllProduct(1);
  }, []);
  const getAllCustomer = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopUser(payload)
      if (res.status) {
        setUserList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
    }
  }
  const getAllProduct = async (payload) => {
    try {
      const res = await WhopServices.getAllWhopProduct(payload)
      if (res.status) {
        setProductList(res.data.data);
      }
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
    }
  }

  const getAllLicenses = async (payload) => {
    setIsLoading(true);
    try {
      const res = await WhopServices.getAllWhopLicense(payload)
      if (res.status) {
        setLicenseList(res.data.data);
        // toast.success(res?.data?.message);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllModules")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  const onStatusUpdate = (wp_lic_id, lic_is_active) => {
    let payload = {
      table_name: "master_whop_license",
      table_field: "wp_lic_id",
      table_field_val: wp_lic_id,
      table_status_field: "lic_is_active",
      table_status_val: lic_is_active ? false : true,
      table_text: "License",
      deleted_by_key: "lic_updated_by",
      delete_active_txt: lic_is_active ? " Disabled" : " Enabled",
    }
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllLicenses(3);
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }

  let initialValues = {
    wp_lic_id: "",
    fk_wp_user_id: "",
    fk_wp_prod_id: "",
    lic_mem_id: "",
    lic_affiliate_username: "",
    lic_checkout_id: "",
    lic_company_buyer_id: "",
    license_key: "",
    lic_manage_url: "",
    lic_page_id: "",
    lic_plan_id: "",
    lic_product_id: "",
    lic_status: "",
    lic_user_id: "",
    lic_quantity: "",
    lic_metadata: "",
    lic_cancel_at_period_end: "",
    lic_marketplace: "",
    lic_valid: "",
    lic_renewal_period_end: "",
    lic_renewal_period_start: "",
    lic_created_at: "",
    lic_expires_at: "",
    lic_is_active: true,
  };

  const [formLicense, setFormLicense] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    setSelectedProductId('');
    setSelectedUserId('');
    values.fk_wp_user_id = ""
    values.fk_wp_prod_id = ""
    if ((type === "edit", id)) {
      setLicenseid(id);
      const initialValues = {
        wp_lic_id: obj.wp_lic_id,
        fk_wp_user_id: obj.fk_wp_user_id,
        fk_wp_prod_id: obj.fk_wp_prod_id,
        lic_mem_id: obj.lic_mem_id,
        lic_affiliate_username: obj.lic_affiliate_username,
        lic_checkout_id: obj.lic_checkout_id,
        lic_company_buyer_id: obj.lic_company_buyer_id,
        license_key: obj.license_key,
        lic_manage_url: obj.lic_manage_url,
        lic_page_id: obj.lic_page_id,
        lic_plan_id: obj.lic_plan_id,
        lic_product_id: obj.lic_product_id,
        lic_status: obj.lic_status,
        lic_user_id: obj.lic_user_id,
        lic_quantity: obj.lic_quantity,
        lic_metadata: obj.lic_metadata,
        lic_cancel_at_period_end: obj.lic_cancel_at_period_end,
        lic_marketplace: obj.lic_marketplace,
        lic_valid: obj.lic_valid,
        
        lic_renewal_period_end: moment(obj?.lic_renewal_period_end).format("YYYY-MM-DD"),
        lic_renewal_period_start: moment(obj?.lic_renewal_period_start).format("YYYY-MM-DD"),
        lic_created_at: moment(obj?.lic_created_at).format("YYYY-MM-DD"),
        lic_expires_at: moment(obj?.lic_expires_at).format("YYYY-MM-DD"),
        lic_is_active: obj.lic_is_active,
      };
      if (Array.isArray(productList) && productList.length > 0) {
        let product = productList.filter(v => v.value == initialValues.fk_wp_prod_id)
        if (product.length > 0) {
          setSelectedProductId({ value: product[0].value, label: product[0].label })
        }
      }
      if (Array.isArray(userList) && userList.length > 0) {
        let User = userList.filter(v => v.value == initialValues.fk_wp_user_id)
        if (User.length > 0) {
          setSelectedUserId({ value: User[0].value, label: User[0].label })
        }
      }
      setFormLicense(initialValues);
    } else {
      setLicenseid("");
      setFormLicense(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (wp_lic_id, lic_is_active) => {
    setConfirmationModal({ wp_lic_id, lic_is_active, status: true });
  };

  const onDeleteOpenSection = (wp_lic_id, lic_is_deleted) => {
    setRemoveConfirmationModal({ wp_lic_id, lic_is_deleted, status: true });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formLicense,
      validationSchema: licenseSchema,
      onSubmit: async (values, action) => {

        let body = {
          wp_lic_id: wp_lic_id,
          fk_wp_user_id: values.fk_wp_user_id,
          fk_wp_prod_id: values.fk_wp_prod_id,
          lic_mem_id: values.lic_mem_id,
          lic_affiliate_username: values.lic_affiliate_username,
          lic_checkout_id: values.lic_checkout_id,
          lic_company_buyer_id: values.lic_company_buyer_id,
          license_key: values.license_key,
          lic_manage_url: values.lic_manage_url,
          lic_page_id: values.lic_page_id,
          lic_plan_id: values.lic_plan_id,
          lic_product_id: values.lic_product_id,
          lic_status: values.lic_status,
          lic_user_id: values.lic_user_id,
          lic_quantity: values.lic_quantity,
          lic_metadata: values.lic_metadata,
          lic_cancel_at_period_end: values.lic_cancel_at_period_end,
          lic_marketplace: values.lic_marketplace,
          lic_valid: values.lic_valid,
          lic_renewal_period_end: values.lic_renewal_period_end,
          lic_renewal_period_start: values.lic_renewal_period_start,
          lic_created_at: values.lic_created_at,
          lic_expires_at: values.lic_expires_at,
          lic_is_active: true,
        };

        if (body.lic_affiliate_username != undefined || body.lic_affiliate_username != null || body.lic_affiliate_username != "") {
          setTimeout(() => {
            setDisableSubmitButton(false)
          }, 1000)
          setDisableSubmitButton(true)
          setLicenseList([]);
          setIsLoading(true);
          let { data, status, message } = await WhopServices.SaveWhopLicense(body)
          if (status === 200) {
            if (data.status) {
              action.resetForm();
              toast.success(data.message);
              getAllLicenses(3);
              setIsLoading(false)
              setmodalOpenFlage(false);
              setDisableSubmitButton(false)
            } else {
              toast.success(data.message);
              setErrorMessage(data.message);
              setTimeout(() => {
                setErrorMessage('')
              }, 1000)
              setIsLoading(false);
              setDisableSubmitButton(false);
            }
          } else {
            setDisableSubmitButton(false);
          }
        } else {
          setDisableSubmitButton(false)

          action.resetForm();
          if (modalOpenFlage === true) {
            getAllLicenses(3);
            setmodalOpenFlage(false);
          }
          setDisableSubmitButton(false)
        }
      },
    });

  const onDeleteLicense = async (wp_lic_id) => {
    let { data, status } = await WhopServices.deleteWhopLicense({ wp_lic_id })
    if (status === 200) {
      if (data.status) {

        toast.success(data.message);
        getAllLicenses(3);
      }
    } else {

      toast.error(data.message);
    }
    setRemoveConfirmationModal({ wp_lic_id: null, status: false });
  };

  const filterUser = (User) => {
    const result = userList.filter(obj => obj?.user_name?.toLowerCase() === User?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].wp_user_id
    } else {
      resp = undefined;
    }
    return resp;
  }

  const filterProduct = (product) => {
    const result = productList.filter(obj => obj?.prod_name?.toLowerCase() === product?.toLowerCase());
    let resp = null;
    if (result.length > 0) {
      resp = result[0].wp_prod_id
    } else {
      resp = undefined;
    }
    return resp;
  }

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          console.log("License handleImport: ", rows);
          let newData = [];
          if (rows.length > 0) {

            rows.map((obj, index) => {
              if (obj.lic_affiliate_username != undefined || obj.license_key != undefined) {
                const newObj = {
                  wp_lic_id: obj.wp_lic_id !== undefined ? obj.wp_lic_id ? obj.wp_lic_id.trim() : obj.wp_lic_id.trim() : '',
                  lic_mem_id: obj.lic_mem_id !== undefined ? obj.lic_mem_id ? obj.lic_mem_id.trim() : obj.lic_mem_id.trim() : '',
                  lic_affiliate_username: obj.lic_affiliate_username !== undefined ? obj.lic_affiliate_username ? obj.lic_affiliate_username.toString().trim() : obj.lic_affiliate_username.toString().trim() : '',
                  lic_checkout_id: obj.lic_checkout_id !== undefined ? obj.lic_checkout_id ? obj.lic_checkout_id.trim() : obj.lic_checkout_id.trim() : '',
                  lic_company_buyer_id: obj.lic_company_buyer_id !== undefined ? obj.lic_company_buyer_id ? obj.lic_company_buyer_id.trim() : obj.lic_company_buyer_id.trim() : '',
                  license_key: obj.license_key !== undefined ? obj.license_key ? obj.license_key.trim() : obj.license_key.trim() : '',
                  lic_manage_url: obj.lic_manage_url !== undefined ? obj.lic_manage_url ? obj.lic_manage_url.trim() : obj.lic_manage_url.trim() : '',
                  lic_page_id: obj.lic_page_id !== undefined ? obj.lic_page_id ? obj.lic_page_id.trim() : obj.lic_page_id.trim() : '',
                  lic_plan_id: obj.lic_plan_id !== undefined ? obj.lic_plan_id ? obj.lic_plan_id.trim() : obj.lic_plan_id.trim() : '',
                  lic_product_id: obj.lic_product_id !== undefined ? obj.lic_product_id ? obj.lic_product_id.trim() : obj.lic_product_id.trim() : '',
                  lic_status: obj.lic_status !== undefined ? obj.lic_status ? obj.lic_status.trim() : obj.lic_status.trim() : '',
                  lic_user_id: obj.lic_user_id !== undefined ? obj.lic_user_id ? obj.lic_user_id.trim() : obj.lic_user_id.trim() : '',
                  lic_quantity: obj.lic_quantity !== undefined ? obj.lic_quantity ? obj.lic_quantity.trim() : obj.lic_quantity.trim() : '',
                  lic_cancel_at_period_end: obj.lic_cancel_at_period_end !== undefined ? obj.lic_cancel_at_period_end ? obj.lic_cancel_at_period_end.trim() : obj.lic_cancel_at_period_end.trim() : '',
                  lic_marketplace: obj.lic_marketplace !== undefined ? obj.lic_marketplace ? obj.lic_marketplace.trim() : obj.lic_marketplace.trim() : '',
                  lic_valid: obj.lic_valid !== undefined ? obj.lic_valid ? obj.lic_valid.trim() : obj.lic_valid.trim() : '',
                  lic_renewal_period_end: obj.lic_renewal_period_end !== undefined ? obj.lic_renewal_period_end ? obj.lic_renewal_period_end.trim() : obj.lic_renewal_period_end.trim() : '',
                  lic_renewal_period_start: obj.lic_renewal_period_start !== undefined ? obj.lic_renewal_period_start ? obj.lic_renewal_period_start.trim() : obj.lic_renewal_period_start.trim() : '',
                  lic_created_at: obj.lic_created_at !== undefined ? obj.lic_created_at ? obj.lic_created_at.trim() : obj.lic_created_at.trim() : '',
                  lic_expires_at: obj.lic_expires_at !== undefined ? obj.lic_expires_at ? obj.lic_expires_at.trim() : obj.lic_expires_at.trim() : '',
                  fk_wp_user_id: filterUser(obj.user_name),
                  fk_wp_prod_id: filterProduct(obj.prod_name),
                };

                newData.push(newObj);
              }

            });

            console.log("newArray: ", newData);
            const filteredArray = newData.filter(obj => obj.fk_wp_user_id !== undefined && obj.fk_wp_prod_id !== undefined);
            console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              importLicense(newData);
            }

          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  const importLicense = (data) => {
    const payload = { excel_data: data }
    MasterServices.userExcelImport(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("License Imported Successfully");
          getAllLicenses(3);
        } else {
          toast.error(data.message)
        }
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleExport = (excel_type) => {
    if (!licenseList || licenseList.length === 0) {
      toast.error("License list is empty!");
      return;
    }
    if (licenseList?.length > 0) {
      let newSheetdata = [];
      licenseList.map((obj, index) => {
        const newData = {
          SN: parseInt(index) + 1,
          wp_lic_id: obj?.wp_lic_id || '-',
          lic_affiliate_username: capitalizeFirstLetter(obj?.lic_affiliate_username) || '-',
          UserName: capitalizeFirstLetter(obj?.master_whop_user_relation?.user_name) || '-',
          ProductName: capitalizeFirstLetter(obj?.master_whop_product_relation?.prod_name) || '-',
          lic_mem_id: obj?.lic_mem_id || '-',
          lic_checkout_id: obj?.lic_checkout_id || '-',
          lic_company_buyer_id: obj?.lic_company_buyer_id || '-',
          license_key: obj?.license_key || '-',
          lic_manage_url: obj?.lic_manage_url || '-',
          lic_page_id: obj?.lic_page_id || '-',
          lic_plan_id: obj?.lic_plan_id || '-',
          lic_product_id: obj?.lic_product_id || '-',
          lic_user_id: obj?.lic_user_id || '-',
          lic_status: obj?.lic_status || '-',
          lic_quantity: obj?.lic_quantity || '-',
          lic_cancel_at_period_end: obj?.lic_cancel_at_period_end || '-',
          lic_marketplace: obj?.lic_marketplace || '-',
          lic_valid: obj?.lic_valid || '-',
          lic_renewal_period_start: moment(obj?.lic_renewal_period_start).format("DD-MM-YYYY") || '-',
          lic_renewal_period_end: moment(obj?.lic_renewal_period_end).format("DD-MM-YYYY") || '-',
          lic_created_at: moment(obj?.lic_created_at).format("DD-MM-YYYY") || '-',
          lic_expires_at: moment(obj?.lic_expires_at).format("DD-MM-YYYY") || '-',
          lic_created_date: moment(obj?.lic_created_date).format("DD-MM-YYYY") || '-',
          Status: obj?.lic_is_active == true ? 'Active' : 'In-Active' || '-',
        }
        newSheetdata.push(newData);
      });
      let currentDate = new Date();
      let fileName = 'License_Excel_Report_' + moment(currentDate).format("DD-MM-YYYY") + '.' + excel_type;
      const headings = [['SN', 'License ID', 'Affiliate Username', 'Username', 'Product Name', 'License Unique ID', 'License Checkout Id', 'License Company Buyer ID', 'License Key', 'License Manage Url', 'Page ID', 'Plan ID', 'Product ID', 'User ID', 'License Status', 'Quantity', 'Cancel At Period End', 'Marketplace', 'Valid', 'Renewal Period Start', 'Renewal Period End', 'License Created At', 'License Expires At', 'Created Date', 'Status']];
      const wb = utils.book_new();
      const ws = utils.json_to_sheet([]);
      utils.sheet_add_aoa(ws, headings);
      utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
      utils.book_append_sheet(wb, ws, 'Report');
      writeFile(wb, fileName);
    } else {
      toast.error("Data Not Found!");
    }
  }



  return isLoading ? (
    <FallingLinesLoader />
  ) : (
    <div className="px-4 sm:px-4 lg:px-4">
      <ConfirmationModal
        title={
          "Are you sure you want to " +
          (confirmationModal.lic_is_active ? "Disable " : "Enable") +
          " this License?"
        }
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() =>
          onStatusUpdate(
            confirmationModal.wp_lic_id,
            confirmationModal.lic_is_active
          )
        }
        setOpen={setConfirmationModal}
      />

      <DeleteConfirmationModal
        title={
          "Are you sure you want to " +
          (removeConfirmationModal.lic_is_deleted
            ? " Undo"
            : "Delete") +
          " this License?"
        }
        confirmationButtonText="Yes"
        open={removeConfirmationModal.status}
        onDelete={() => {
          console.log("confirmationModal = ", removeConfirmationModal);
          onDeleteLicense(removeConfirmationModal.wp_lic_id);
        }}
        setOpen={setRemoveConfirmationModal}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">License List</h1>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 34)?.length >
          0 ? (
          <Link
            onClick={() => handleDrawer("add", "", {})}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            <PlusCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Add License
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 49)?.length > 0 && licenseList?.length > 0 ? (
          <Link
            to={"#"}
            onClick={() => handleExport('xlsx')}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-2"
          >
            <ArrowDownCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Download Excel
          </Link>
        ) : null}
        {allowedActions.filter((data) => data.permission_id == 50)?.length > 0 ? (
          <label htmlFor="file-upload" className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-3 py-1 text-xs font-medium text-white shadow-sm hffff  ml-2 cursor-pointer transition duration-300 ease-in-out">
            <ArrowUpCircleIcon className="-ml-1.5 h-5 w-5 mr-1" aria-hidden="true" />
            Upload License
            <input id="file-upload" type="file" className="hidden" onChange={handleImport} />
          </label>
        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={license_columns({
            onDeleteOpen,
            onDeleteOpenSection,
            handleDrawer,
            allowedActions,
          })}
          data={licenseList}
          is_toggle={false}
        />
      )}

      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {wp_lic_id ? "Update" : "Add"} License
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md  text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="p-4 sm:p-6">
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_affiliate_username"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Name
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_affiliate_username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="License Name"
                                    name="lic_affiliate_username"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_affiliate_username &&
                                    touched.lic_affiliate_username ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_affiliate_username.replace("lic_affiliate_username", "License Name")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_quantity"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Quantity
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="number"
                                    placeholder="Quantity"
                                    name="lic_quantity"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_quantity &&
                                    touched.lic_quantity ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_quantity.replace("lic_quantity", "Quantity")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                              {userList && userList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="user_name"
                                    id="wp_user_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select User
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedUserId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_wp_user_id = e.value;
                                      setSelectedUserId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      userList
                                        ? userList.map((v) => ({
                                          value: v.wp_user_id,
                                          label: v.user_name,
                                        }))
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_user_id && touched.fk_wp_user_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_user_id.replace("fk_wp_user_id", "User")}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {productList && productList.length > 0 ? (
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="prod_name"
                                    id="wp_prod_id"
                                    menuPlacement="top"
                                    placeholder={
                                      <span>
                                        Select Product
                                        <span className="text-red-600">*</span>
                                      </span>
                                    }
                                    value={selectedProductId}
                                    onChange={(e) => {
                                      console.log(e);
                                      values.fk_wp_prod_id = e.value;
                                      setSelectedProductId(e);
                                    }}
                                    components={animatedComponents}
                                    options={
                                      productList
                                        ? productList.map((v) => ({
                                          value: v.wp_prod_id,
                                          label: v.prod_name,
                                        }))
                                        : []
                                    }
                                    classNamePrefix="select"
                                    className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />

                                  {errors.fk_wp_prod_id && touched.fk_wp_prod_id ? (
                                    <div className="text-sm text-red-600">
                                      {errors.fk_wp_prod_id.replace("fk_wp_prod_id", "Product Name")}
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_renewal_period_start"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Renewal Period Start
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_renewal_period_start}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="date"
                                    placeholder="Renewal Period Start"
                                    name="lic_renewal_period_start"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_renewal_period_start &&
                                    touched.lic_renewal_period_start ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_renewal_period_start.replace("lic_renewal_period_start", "Renewal Period Start")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_renewal_period_end"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Renewal Period End
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_renewal_period_end}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="date"
                                    placeholder="Renewal Period End"
                                    name="lic_renewal_period_end"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_renewal_period_end &&
                                    touched.lic_renewal_period_end ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_renewal_period_end.replace("lic_renewal_period_end", "Renewal Period End")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_created_at"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Created Date
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_created_at}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="date"
                                    placeholder="Created Date"
                                    name="lic_created_at"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_created_at &&
                                    touched.lic_created_at ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_created_at.replace("lic_created_at", "Created Date")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                <div>
                                  <label
                                    htmlFor="lic_expires_at"
                                    className="block text-sm font-medium text-gray-900 mb-1"
                                  >
                                    Expired Date
                                    <span className="text-red-600">*</span>
                                  </label>
                                </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.lic_expires_at}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="date"
                                    placeholder="Expired Date"
                                    name="lic_expires_at"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.lic_expires_at &&
                                    touched.lic_expires_at ? (
                                    <p className="text-red-600 text-sm">
                                      {errors.lic_expires_at.replace("lic_expires_at", "Expired Date")}
                                    </p>
                                  ) : null}
                                </div>
                              </div>

                              <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                <div className="basis-1/2 text-red-500 text-sm">
                                  {errorMessage !== "" &&
                                    "Error: " + errorMessage}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={disableSubmitButton}
                            type="submit"
                            className={`ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                              ? "bg-gray-700 hover:bg-gray-700"
                              : "bg-cyan-700"
                              }`}
                          >
                            {wp_lic_id ? (
                              disableSubmitButton ? (
                                <FaSpinner color="gold" />
                              ) : (
                                "Update"
                              )
                            ) : disableSubmitButton ? (
                              <FaSpinner color="gold" />
                            ) : (
                              "Add"
                            )}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default LicenseList;