import { Fragment } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ActionMenu = ({ handleClick, details }) => {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <MenuItem>
              {({ focus }) => (
                <a
                  href="javascript:;"
                  className={classNames(
                    focus ? 'bg-gray-100 text-orange-400' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    handleClick({type: 0, id: details});
                  }}
                >
                  Edit
                </a>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <a
                  href="javascript:;"
                  className={classNames(
                    focus ? 'bg-gray-100 text-red-700' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    handleClick({type: 1, id: details});
                  }}
                >
                  Delete
                </a>
              )}
            </MenuItem>
            {/*
            <MenuItem>
              {({ focus }) => (
                <a
                  href="javascript:;"
                  className={classNames(
                    focus ? 'bg-gray-100 text-blue-600' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => {
                    handleClick({type: 2, id: details});
                  }}
                >
                  Show Details
                </a>
              )}
            </MenuItem>
            */}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

export default ActionMenu;
