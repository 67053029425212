/* eslint-disable */
import Api from "./Api";
// import AuthToken from "./AuthTokenServices";

export default {

  async getAllCopyTrade(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/copy-trade?search=${payload}`);
    return response;
  },
  async getAllOldCopyTrade(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/copy-trade/old?search=${payload}`);
    return response;
  },

  async saveCopyAccount(payload) {
    // await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/copy-trade`, payload);
    return response;
  },

};
