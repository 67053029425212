import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { groupSchema } from "../../../../schemas";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import RemoveConfirmationModal from "../../../../components/DeleteConfirmationModal";
import DeleteAccConfirmationModal from "../../../../components/DeleteAccConfirmationModal";
import { FaSpinner } from "react-icons/fa";
import GroupServices from "../../../../ApiServices/GroupServices";
import GroupAccServices from "../../../../ApiServices/GroupAccServices";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import FSLoader from "../../../../components/FSLoader";
import GroupCard from './GroupCard';
import emptyImg from "../../../../assets/images/empty_common.png"
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Select from "react-select";
import CopyAccountServices from "../../../../ApiServices/CopyAccountServices";
import LoadingButton from "../../../../components/LoadingButton";

function GroupList() {
	const pages = [{ title: "CT Group", href: "/group", module_id: 3 }];
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [groupList, setGroupList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFSLoading, setSFLoading] = useState(false);
	const [isSaveBtnApiCall, setIsSaveBtnApiCall] = useState(false);
	const [loadingRemoveGrpIndex, setLoadingRemoveGrpIndex] = useState(-1);
	const [openAddGroup, setOpenAddGroup] = useState(false);
	const [openAddGroupAcc, setOpenAddGroupAcc] = useState(false);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [grp_id, setGroupid] = useState(0);
	const [gam_id, setGroupMapid] = useState(0);
	const [groupMapShowType, setGroupMapShowType] = useState(-1);
	const [addCurrentAccStatus, setAddCurrentAccStatus] = useState(-1);
	const [accountList, setAccountList] = useState([]);
	const [accountSelected, setAccountSelected] = useState(null);
	const [currentAddAccGroup, setCurrentAddAccGroup] = useState(null);
	const [senderVal, setSenderVal] = useState(null);
	const [receiverVal, setReceiverVal] = useState(null);
	const [riskType, setRiskType] = useState(null);
	const [multiplier, setMultiplier] = useState(null);
	const [initValAddAcc, setInitValAddAcc] = useState({
		/* gam_id: 0,
		gam_acc_no: "",
		fk_mua_id: "",
		gam_grp_name: "",
		fk_grp_id: "", */
		gam_risk_type: "NONE",
		gam_multiplier: 0,
		gam_acc_type: "",
		gam_ss: "",
		gam_rs: "",
	});

	const initialValues = {
		grp_id: "",
		grp_name: "",
		grp_risk_type: "",
		grp_multiplier: "",
		grp_is_active: "",
	};

	const [formGroup, setFormGroup] = useState(initialValues);

	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		grp_acc_status: 0,
		mua_acc_login: "",
		gam_id: null,
		fk_grp_id: null,
	});
	const [removeConfirmationModal, setRemoveConfirmationModal] = useState({
		status: false,
		grp_id: null,
	});
	const [removeAccConfirmationModal, setRemoveAccConfirmationModal] = useState({
		status: false,
		gam_id: null,
	});


	const OnChangeAccountType = (fk_grp_id, gam_is_smo, account, mua_acc_login, accAr, grp_acc_status) => {

		let gam_id = account?.gam_id;
		const filterData = accAr?.filter((item) => item.gam_is_smo == 1)
		let title = "Are you sure you want to modify?";
		if (grp_acc_status == 1) {
			if (filterData.length > 0) {
				title = "Are you sure you want to Activate Master For Account No : " + (mua_acc_login) + " One Account Already Have Master Function On " + (filterData.length > 0 ? filterData[0]?.mt_user_account_relation?.mua_acc_login : "");
			} else {
				let Status = getStatusTitle(grp_acc_status);
				title = "Are you sure you want to Activate " + Status + " For Account No : " + (mua_acc_login);
			}
		} else {
			let Status = getStatusTitle(grp_acc_status);
			title = "Are you sure you want to Activate " + Status + " For Account No : " + (mua_acc_login);

		}

		setConfirmationModal({ fk_grp_id, gam_id, mua_acc_login, grp_acc_status, status: true, title });
	};

	<ConfirmationModal
		title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
		confirmationButtonText="Yes"
		open={confirmationModal.status}
		onDelete={() => {
			onStatusUpdate(
				confirmationModal.fk_grp_id,
				confirmationModal.gam_id,
				confirmationModal.acc_status,
				confirmationModal.grp_acc_status,
				confirmationModal.mua_acc_login
			);
		}
		}
		setOpen={setConfirmationModal}
	/>

	const onStatusUpdate = async (fk_grp_id, gam_id, acc_status, mua_acc_login, grp_acc_status) => {
		let payload = {
			gam_id: gam_id,
			fk_grp_id: fk_grp_id,
			mua_acc_login: mua_acc_login,
			grp_acc_status: grp_acc_status,
			gam_is_smo: 1,
			StatusTitle: getStatusTitle(grp_acc_status)
		}
		let { data, status } = await GroupAccServices.updateGroupAccStatus(payload)
		if (status === 200) {
			if (data.status) {

				toast.success(data.message);
				getAllGroups(1);
			}
		} else {
			//toast.error(data.message);
		}
		setConfirmationModal({ fk_grp_id: null, gam_id: null, mua_acc_login: "", status: false });
		getAllGroups(1);
	}

	const getStatusTitle = (grp_acc_status) => {
		if (grp_acc_status === 0) {
			return "Off";
		} else if (grp_acc_status === 1) {
			return "Master";
		} else if (grp_acc_status === 2) {
			return "Slave";
		} else {
			return "";
		}
	};

	const navigate = useNavigate();


	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Maqueensoft  • Groups";
		getAllTradeAcc(1);
		getAllGroups(1);
	}, []);

	const getAllTradeAcc = async (payload) => {
		try {
			const res = await CopyAccountServices.getAllCopyTrade(payload)
			if (res.status) {
				setAccountList(res.data.data);
			}
		} catch (e) {
			console.log(e, "error in getAllModules");
			//toast.error("Fatal Error");
		}
	}

	const getAllGroups = async (payload) => {
		setIsLoading(true);
		try {
			const res = await GroupServices.getAllGroup(payload)
			if (res.status) {
				setGroupList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllModules")
			//toast.error("Fatal Error")
			setIsLoading(false);
		}
	}

	const onAddAccount = (data) => {
		console.log(data);
		setCurrentAddAccGroup(data);
		setOpenAddGroupAcc(true);
	}

	const handleAccStateChange = (data) => {
		setAddCurrentAccStatus(data)
	}

	const handleAccountChange = (data) => {
		setAccountSelected(data);
	}

	const handleSenderSuffixChange = (data) => {
		setSenderVal(data);
	}

	const handleReceiverSuffixChange = (data) => {
		setReceiverVal(data);
	}

	const handleRiskTypeChange = (data) => {
		setRiskType(data);
	}
	const handleMultiplierChange = (data) => {
		setMultiplier(data);
	}


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formGroup,
			validationSchema: groupSchema,
			onSubmit: async (values, action) => {

				setIsSaveBtnApiCall(true);

				let body = {
					grp_id: grp_id,
					grp_is_active: true,
					grp_name: values.grp_name,
					grp_risk_type: values.grp_risk_type,
					grp_multiplier: values.grp_multiplier,
				};
				if (grp_id === undefined || grp_id === null || grp_id === "") {
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.saveGroup(body)
				} else {

					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					let { data, status, message } = await GroupServices.saveGroup(body)
				}
				action.resetForm();
				getAllGroups(3);
				setOpenAddGroup(false);
				if (modalOpenFlage === true) {
					getAllGroups(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				setIsSaveBtnApiCall(false);
			},
		});

	const addAccountFormik = useFormik({
		initialValues: initValAddAcc,
		validate: values => {
			const errors = {};
			/* if (!values.fieldA) {
				errors.fieldA = 'Required';
			} */
			return errors;
		},
		onSubmit: async values => {

			setIsSaveBtnApiCall(true);
			console.log("accountSelected=>", accountSelected)
			if (accountSelected) {
				const payload = {
					gam_id: gam_id,
					gam_acc_no: accountSelected ? accountSelected.label : "",
					gam_grp_name: groupMapShowType == 0 ? initValAddAcc.gam_grp_name : currentAddAccGroup ? currentAddAccGroup?.grp_name : "N/A",
					fk_grp_id: groupMapShowType == 0 ? initValAddAcc.fk_grp_id : currentAddAccGroup ? parseInt(currentAddAccGroup?.grp_id) : 0,
					fk_mua_id: accountSelected?.mua_id ? accountSelected?.mua_id : accountSelected?.value ? accountSelected?.value : 0,
					gam_risk_type: values.gam_risk_type ? values.gam_risk_type : "NONE",
					gam_multiplier: values.gam_multiplier ? values.gam_multiplier : "0",
					gam_acc_type: addCurrentAccStatus ? addCurrentAccStatus : 0,
					gam_ss: values?.gam_ss ? values?.gam_ss : "",
					gam_rs: values?.gam_rs ? values?.gam_rs : "",
				}

				//gam_id, fk_grp_id, fk_mua_id, gam_is_smo, gam_risk_type, gam_multiplier
				const response = await GroupAccServices.saveGroupAcc(payload);

				if (response.data.status) {
					setOpenAddGroupAcc(false);
					toast.success(response.data.message)
				} else {
					setOpenAddGroupAcc(false);
					toast.error(response.data.message)
				}

				setIsSaveBtnApiCall(false);
			} else {
				setIsSaveBtnApiCall(false);
				alert("Please, Select Account No");
			}
			getAllGroups(1);
		},
	});

	const handleClickMenu = (data) => {
		console.log(data);
		setGroupMapid(data?.id?.gam_id);
		// return
		setGroupMapShowType(data.type);
		if (data.type == 1) {//Delete
			handleRemoveGroupAcc(data.id);
		}
		if (data.type == 2 || data.type == 0) {
			setCurrentAddAccGroup(data.id);
			setOpenAddGroupAcc(true);
			let gam_data = data?.id;
			setInitValAddAcc({
				gam_id: gam_data?.gam_id,
				gam_acc_no: gam_data?.gam_acc_no,
				fk_mua_id: gam_data?.fk_mua_id,
				gam_grp_name: gam_data?.gam_grp_name,
				fk_grp_id: gam_data?.fk_grp_id,

				gam_risk_type: gam_data?.gam_risk_type,
				gam_multiplier: gam_data?.gam_multiplier,
				gam_acc_type: gam_data?.gam_acc_type,
				gam_ss: gam_data?.gam_ss,
				gam_rs: gam_data?.gam_rs,
			})
			setAddCurrentAccStatus(gam_data?.gam_acc_type);
			setRiskType(gam_data?.gam_risk_type);
			setMultiplier(gam_data?.gam_multiplier);
			setSenderVal(gam_data?.gam_ss);
			setReceiverVal(gam_data?.gam_rs);
			setAccountSelected({ label: gam_data?.mt_user_account_relation?.mua_acc_login, value: gam_data?.mt_user_account_relation?.mua_id })
			setCurrentAddAccGroup({ label: gam_data?.m_group_relation?.grp_name, value: gam_data?.m_group_relation?.grp_id })
		}
	}

	const handleEditGroup = (data) => {
		console.log(data);
		setFormGroup({
			grp_id: data?.grp_id,
			grp_name: data?.grp_name,
			grp_risk_type: data?.grp_risk_type,
			grp_multiplier: data?.grp_multiplier,
			grp_is_active: data?.grp_is_active,
		});
		setGroupid(data?.grp_id);

		setOpenAddGroup(true)
	}
	const handleRemoveGroup = (data, index) => {
		setLoadingRemoveGrpIndex(index);
		let title = "Are you sure you want to Remove Group Data For Group Name " + data?.grp_name;
		setRemoveConfirmationModal({ grp_id: data?.grp_id, status: true, title });
	}

	const onDeleteGroup = async (grp_id, grp_status) => {
		let payload = {
			grp_id: grp_id,
			grp_status: !grp_status,
		}
		let { data, status } = await GroupServices.deleteGroup(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {

				toast.success(data.message);
				getAllGroups(1);
			}
		} else {
			//toast.error(data.message);
		}
		setRemoveConfirmationModal({ grp_id: null, status: false });
		getAllGroups(1);
		setLoadingRemoveGrpIndex(-1);
	}

	const handleRemoveGroupAcc = (data) => {
		let title = "Are you sure you want to Remove Group Acc No " + data?.gam_acc_no;
		setRemoveAccConfirmationModal({ gam_id: data?.gam_id, status: true, title });
	}
	const onDeleteGroupAcc = async (gam_id, gam_status) => {
		let payload = {
			gam_id: gam_id,
			gam_status: !gam_status,
		}
		let { data, status } = await GroupAccServices.deleteGroupAcc(payload);
		// Simulate an async action

		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		}
		setRemoveAccConfirmationModal({ gam_id: null, status: false });
		getAllGroups(1);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<FSLoader isLoading={isFSLoading} title="Processing..." />

			<Breadcrumb pages={pages} />
			<ConfirmationModal
				title={confirmationModal?.title ? confirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={confirmationModal.status}
				onDelete={() => {
					onStatusUpdate(
						confirmationModal.fk_grp_id,
						confirmationModal.gam_id,
						confirmationModal.acc_status,
						confirmationModal.mua_acc_login,
						confirmationModal.grp_acc_status,
					);
					console.log("confirmationModal.mua_acc_login=>", confirmationModal);
				}
				}
				setOpen={setConfirmationModal}
			/>

			<RemoveConfirmationModal
				title={removeConfirmationModal?.title ? removeConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeConfirmationModal.status}
				onDelete={() => {
					onDeleteGroup(
						removeConfirmationModal.grp_id,
						removeConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveConfirmationModal}
			/>

			<DeleteAccConfirmationModal
				title={removeAccConfirmationModal?.title ? removeAccConfirmationModal?.title : "Are you sure you want to modify settings?"}
				confirmationButtonText="Yes"
				open={removeAccConfirmationModal.status}
				onDelete={() => {
					onDeleteGroupAcc(
						removeAccConfirmationModal.gam_id,
						removeAccConfirmationModal.status,
					);
				}
				}
				setOpen={setRemoveAccConfirmationModal}
			/>


			<div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
				<h3 className="text-base font-semibold leading-6 text-gray-900">Copy Trading - Group</h3>
				<div className="mt-3 sm:ml-4 sm:mt-0">
					<button
						onClick={() => {
							setOpenAddGroup(true)
						}}
						type="button"
						className="inline-flex items-center rounded-md bg-[#0172fd] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						+ Add New Group
					</button>
				</div>
			</div>

			<div className="mt-4 flex">

			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<div className="container mx-auto p-4">
					<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
						{
							groupList.map((group, ind) => (
								<GroupCard index={ind} deleteIndex={loadingRemoveGrpIndex} key={group.grp_id} group={group} OnChangeAccountType={OnChangeAccountType} onAddAccount={onAddAccount} handleClickMenu={handleClickMenu} handleEditGroup={handleEditGroup}
									handleRemoveGroup={handleRemoveGroup}
								/>
							))
						}
					</div>
					{
						groupList.length <= 0 ? (
							<div className="border bg-white rounded-md p-4 my-4 w-full flex flex-col text-center items-center justify-between">

								<img src={emptyImg} alt="empty" width="30%" className="text-center items-center justify-between" />
								<p className="text-slate-500">Ops! No Active Goup</p>
								<button
									onClick={() => {
										setOpenAddGroup(true)
									}}
									type="button"
									className="mt-4 mb-4 rounded-sm bg-white px-4 py-2.5 text-sm font-semibold text-[#0172fd] shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
								>
									Let's Add New Group
								</button>

							</div>
						) : null
					}
				</div>
			)}


			{/* Modal for Acc Group */}
			<Transition show={openAddGroup}>
				<Dialog className="relative z-10" onClose={setOpenAddGroup}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center border-b border-[#999] font-semibold leading-6 text-gray-900 pb-3">
												{grp_id > 0 ? 'Update Group' : 'Add New Group'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														console.log(e);
														handleSubmit(e);
													}}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="grp_name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Group Name
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.grp_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="Enter Group Name"
																			name="grp_name"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.grp_name &&
																			touched.grp_name ? (
																			<p className="text-red-600 text-sm">
																				{errors.grp_name}
																			</p>
																		) : null}
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="grp_risk_type"
																			className="block text-sm font-medium text-gray-900 mt-2"
																		>
																			Risk Type
																		</label>
																	</div>
																	<div className="mb-3 sm:col-span-2 sm:mt-0">

																		<select
																			defaultValue={values.grp_risk_type}
																			value={values.grp_risk_type} // Replace with your state field name
																			onChange={handleChange}
																			onBlur={handleBlur}
																			name="grp_risk_type" // Replace with your state field name
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm mt-1"
																		>
																			<option value="NONE" selected>None</option>
																			<option value="RISK_MULTIPLIER_BY_BALANCE">Risk Multiplier By Balance</option>
																			<option value="RISK_MULTIPLIER_BY_EQUITY">Risk Multiplier By Equity</option>
																			<option value="LOT_MULTIPLIER">Lot Multiplier</option>
																			<option value="FIXED_LOT">Fixed Lot</option>
																		</select>
																		{errors.grp_risk_type &&
																			touched.grp_risk_type ? (
																			<p className="text-red-600 text-sm">
																				{errors.grp_risk_type}
																			</p>
																		) : null}
																	</div>
																</div>


																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="grp_multiplier"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Group Multiplier
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.grp_multiplier}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="number"
																			placeholder="Enter Group Multiplier"
																			name="grp_multiplier"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.grp_multiplier &&
																			touched.grp_multiplier ? (
																			<p className="text-red-600 text-sm">
																				{errors.grp_multiplier}
																			</p>
																		) : null}
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
															onClick={() => {
																setOpenAddGroup(false);
															}}
														>
															Cancel
														</button>
														{
															isSaveBtnApiCall ? (
																<LoadingButton title=" Saving" />
															) : (
																<button
																	type="submit"
																	className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700"
																>
																	{grp_id > 0 ? 'Update' : 'Add'} Group
																</button>
															)
														}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>

			{/* Modal for Add Acc in Group */}
			<Transition show={openAddGroupAcc}>
				<Dialog className="relative z-10" onClose={setOpenAddGroupAcc}>
					<TransitionChild
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<TransitionChild
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-2 pb-2 pt-2 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
									<div>
										<div className="mt-2 sm:mt-2">
											<DialogTitle as="h3" className="text-base text-center border-b border-[#999] font-semibold leading-6 text-gray-900 pb-3">
												{groupMapShowType == 2 ? 'Show Account Details' : gam_id <= 0 ? 'Add New Account' : 'Update Account'}
											</DialogTitle>
											<div className="mt-2">
												<form
													onSubmit={addAccountFormik.handleSubmit}
													className="flex h-full flex-col"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="grp_name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Choose Account
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<Select
																			name="Account"
																			defaultValue={accountSelected}
																			value={accountSelected}
																			onChange={(option) => {
																				handleAccountChange(option)
																			}}
																			options={accountList}
																			classNamePrefix="select"
																			menuPortalTarget={document.body}
																			styles={{
																				menuPortal: (base) => ({
																					...base,
																					zIndex: 9999,
																				}),
																			}}
																		/>
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="gam_risk_type"
																			className="block text-sm font-medium text-gray-900 mt-2"
																		>
																			Risk Type
																		</label>
																	</div>
																	<div className="mb-3 sm:col-span-2 sm:mt-0">

																		<select
																			defaultValue={riskType ? riskType : addAccountFormik.values.gam_risk_type}
																			value={riskType ? riskType : addAccountFormik.values.gam_risk_type} // Replace with your state field name
																			// onChange={addAccountFormik.handleChange}
																			onChange={(e) => {
																				handleRiskTypeChange(e.target.value);
																				addAccountFormik.handleChange(e);
																			}}
																			onBlur={addAccountFormik.handleBlur}
																			name="gam_risk_type" // Replace with your state field name
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm mt-1"
																		>
																			<option value="NONE" selected>None</option>
																			<option value="RISK_MULTIPLIER_BY_BALANCE">Risk Multiplier By Balance</option>
																			<option value="RISK_MULTIPLIER_BY_EQUITY">Risk Multiplier By Equity</option>
																			<option value="LOT_MULTIPLIER">Lot Multiplier</option>
																			<option value="FIXED_LOT">Fixed Lot</option>
																		</select>
																		{addAccountFormik.errors.gam_risk_type &&
																			addAccountFormik.touched.gam_risk_type ? (
																			<p className="text-red-600 text-sm">
																				{addAccountFormik.errors.gam_risk_type}
																			</p>
																		) : null}
																	</div>
																</div>


																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="gam_multiplier"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Group Multiplier
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={multiplier ? multiplier : addAccountFormik.values.gam_multiplier}
																			// onChange={addAccountFormik.handleChange}
																			onChange={(e) => {
																				handleMultiplierChange(e.target.value);
																				addAccountFormik.handleChange(e);
																			}}
																			onBlur={addAccountFormik.handleBlur}
																			type="number"
																			placeholder="Enter Group Multiplier"
																			name="gam_multiplier"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{addAccountFormik.errors.gam_multiplier &&
																			addAccountFormik.touched.gam_multiplier ? (
																			<p className="text-red-600 text-sm">
																				{addAccountFormik.errors.gam_multiplier}
																			</p>
																		) : null}
																	</div>
																</div>

																{addCurrentAccStatus == 1 ? (<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="gam_ss"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Symbol Suffix
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={senderVal ? senderVal : addAccountFormik.values.gam_ss}
																			onChange={(e) => {
																				handleSenderSuffixChange(e.target.value);
																				addAccountFormik.handleChange(e);
																			}}
																			// onChange={addAccountFormik.handleChange}
																			onBlur={addAccountFormik.handleBlur}
																			type="text"
																			placeholder="Enter Symbol Suffix"
																			name="gam_ss"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{addAccountFormik.errors.gam_ss &&
																			addAccountFormik.touched.gam_ss ? (
																			<p className="text-red-600 text-sm">
																				{addAccountFormik.errors.gam_ss}
																			</p>
																		) : null}
																	</div>
																</div>):(<></>)}

																{(addCurrentAccStatus == 0 || addCurrentAccStatus == 2) ? (<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="gam_rs"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Symbol Suffix
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={receiverVal ? receiverVal : addAccountFormik.values.gam_rs}
																			// onChange={addAccountFormik.handleChange}
																			onChange={(e) => {
																				handleReceiverSuffixChange(e.target.value);
																				addAccountFormik.handleChange(e);
																			}}
																			onBlur={addAccountFormik.handleBlur}
																			type="text"
																			placeholder="Enter Symbol Suffix"
																			name="gam_rs"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{addAccountFormik.errors.gam_rs &&
																			addAccountFormik.touched.gam_rs ? (
																			<p className="text-red-600 text-sm">
																				{addAccountFormik.errors.gam_rs}
																			</p>
																		) : null}
																	</div>
																</div>):(<></>)}

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label className="block text-sm font-medium text-gray-900 mt-2 mb-1" >
																			Account Type
																		</label>
																	</div>
																	<span className="isolate inline-flex rounded-md shadow-sm">
																		<button
																			type="button"
																			className={` ${addCurrentAccStatus == 2 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 focus:z-10"`}
																			onClick={() => handleAccStateChange(2)}
																		>
																			Slave
																		</button>
																		<button
																			type="button"
																			className={` ${addCurrentAccStatus == 1 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
																			onClick={() => handleAccStateChange(1)}
																		>
																			Master
																		</button>
																		<button
																			type="button"
																			className={` ${addCurrentAccStatus == 0 ? ' bg-gray-700 text-white ' : 'bg-white text-gray-700'} " relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10"`}
																			onClick={() => handleAccStateChange(0)}
																		>
																			Off
																		</button>
																	</span>
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 pb-2">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
															onClick={() => {
																setInitValAddAcc({
																	gam_risk_type: "NONE",
																	gam_multiplier: 0
																})
																setCurrentAddAccGroup(null);
																setAddCurrentAccStatus(-1);
																handleAccountChange(null);
																setOpenAddGroupAcc(false);
																setGroupMapShowType(-1);
																setGroupMapid(0);
															}}
														>
															Cancel
														</button>
														{groupMapShowType !== 2 ? (
															<>
																{
																	isSaveBtnApiCall ? (
																		<LoadingButton title=" Saving" />
																	) : (
																		<button
																			type="submit"
																			className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b bg-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700"
																		>
																			{gam_id > 0 ? 'Update' : 'Add'} Account
																		</button>
																	)
																}
															</>
														) : (<></>)}
													</div>
												</form>
											</div>
										</div>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>


		</div>
	);
}
export default GroupList;
