import React, { Fragment, useEffect, useState, useRef, useCallback } from "react";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast, ToastContainer } from "react-toast";
import { Await, Link, useNavigate, useParams, useLocation } from "react-router-dom";

import FSLoader from "../../../components/FSLoader";
import Table from "../../../components/tables/table";
import { ai_log_columns } from "../../../components/tables/tableheader";
import { utils, writeFile } from 'xlsx';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AccMetrix = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const accid = queryParams.get('accid');

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [aiLog, setAILog] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [isCustomPagination, setIsCustomPagination] = useState(true);
  const [customPageNo, setCustomPageNo] = useState(1);


  const getAllAILog = useCallback(async (pageNo) => {

    let PageCount = pageNo?.pageIndex ? pageNo?.pageIndex : pageNo;

    let payload = `?accid=${accid ? accid : ''}&page=${parseInt(PageCount)}`;
    try {
      const res = await MasterServices.getAILog(payload);
      if (res.status) {
        setAILog(res?.data?.data?.getAllAILog);
        setPageCount(res?.data?.data?.aiLog_pagination?.total_pages);
      }
    } catch (e) {
      console.log(e, "error in getAllAILog");
      toast.error("Oops, Something went wrong...");
    }
  }, [customPageNo]);

  useEffect(() => {
    getAllAILog(customPageNo);
    console.log("on useEffet >>", customPageNo);

  }, [customPageNo, getAllAILog]);
  const setCustomPageNoHandle = (e) => {
    console.log("setCustomPageNoHandle >> ", e);
    setCustomPageNo(e);
  }

  return (
    <>
      {!loading ? (

        <div>

          <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-2">
            <div className="flex justify-between items-center">
              <div className="px-4 py-6 sm:px-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">AI Log</h3>
              </div>
              <div className="px-8 py-6 sm:px-6">
                <div className="flex justify-between items-center">

                </div>
              </div>
            </div>


          </div>


          <div className="px-2 sm:px-6 lg:px-2 overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg bg-gray-50 mb-5">
            <div className="p-4 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

                  <span className="isolate inline-flex rounded-md shadow-sm">


                  </span>

                  <>
                    <Table
                      columns={
                        ai_log_columns({})
                      }
                      data={aiLog ? aiLog : []}
                      is_toggle={true}
                      isCustomPagination={isCustomPagination}
                      fetchData={(page) => getAllAILog(page)}
                      pageCount={pageCount}
                      customPageNo={customPageNo}
                      setCustomPageNo={setCustomPageNoHandle}
                    />
                  </>

                </div>
              </div>
            </div>
          </div>

        </div>



      ) : (

        <div>
          <FSLoader isLoading={isLoading} title="Processing..." />
        </div>

      )}
    </>
  )
}

export default AccMetrix